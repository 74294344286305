// customSortMixin.js
export const customSortMixin = {
  methods: {
    customSort(value, secondarySort) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort

        // Сортировка по первичному полю
        let res = (!a[sortBy]) ? -1 : (!b[sortBy]) ? 1 :
          a[sortBy].toString().localeCompare(b[sortBy].toString(), undefined, { numeric: true })

        // Если первичное поле равное, сортируем по вторичному полю
        if (secondarySort && res === 0) {
          res = (!a[secondarySort]) ? -1 : (!b[secondarySort]) ? 1 :
            a[secondarySort].toString().localeCompare(b[secondarySort].toString(), undefined, { numeric: true })
        }

        // Возвращаем результат с учетом направления сортировки
        return (this.currentSortOrder === 'asc') ? res : -res
      })
    },

    
  }
};
