<!--
File: RoadClassesDropdown.vue
Description: show the dropdown combo with the road classes list .
-->
<template>
  <md-field>
    <label for="road_class">{{ label }}</label>
    <md-select v-model='road_class' name="road_class" id="road_class" @md-selected='onChange' :required="is_required">
      <md-option v-for='(rc, ind) in road_classes' :key='ind' :value='rc.road_class_id'>
        {{ rc.road_class_name }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'road-class-dropdown',

    props: {
      label: { default: null, type: String },
      initial_value: { default: '', type: String },
      is_required: { default: false, type: Boolean }
    },

    data() {
      return {
        road_class: '',
        listLoaded: false
      }
    },

    mounted() {
      this.$store.dispatch('GET_ROADS_CLASSES_DROPDOWN').then(() => {
        this.listLoaded = true
        if (this.initial_value && !this.road_class) this.road_class = this.initial_value
      })
    },

    methods: {
      onChange() {
        this.$emit('input', this.road_class)
      },
    },

    watch: {
      initial_value(newValue, oldValue) {
        if (newValue !== oldValue && this.listLoaded) {
          this.road_class = newValue
        }
      }
    },

    computed: {
      road_classes() {
        return this.$store.state.Dropdowns.road_classes;
      }
    }
  }
</script>